<template>
	<layouts-identify>
		<div class="flex-fill d-flex align-items-center">
			<div class="text-center flex-fill my-10">
				<router-link :to="{ name: 'home' }" class="d-inline-block mb-6">
					<common-logo-main />
				</router-link>
				<h2 class="ls-tight">¿Cómo querés continuar?</h2>
				<p class="mt-1">Elegí alguna de las siguientes opciones</p>
				<common-message-powered class="mt-1" v-if="isDefined" />
			</div>
		</div>
		<div class="mb-10">
			<div class="row g-4">
				<div class="col-12" v-if="googleButton">
					<user-identify-button-google :submitting="submitting" @setUser="setUser" />
				</div>
				<div class="col-12" v-if="facebookButton">
					<user-identify-button-facebook :submitting="submitting" />
				</div>
				<div class="col-12">
					<user-identify-button-phone :submitting="submitting" />
				</div>
				<div class="col-12">
					<user-identify-button-email :submitting="submitting" />
				</div>
			</div>
		</div>
	</layouts-identify>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import LocationRepository from '@/repositories/LocationRepository';
	import composableMicrosite from '@/composables/microsite';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { isDefined, microsite } = composableMicrosite();
			const { redirectNext } = composableRedirect();
			const { submitting, catchError } = composableForm();

			const setUser = async () => {
				submitting.value = true;
				await store.dispatch('auth/me').then(setLocation).catch(catchError);
				submitting.value = false;
			}

			const setLocation = () => {
				LocationRepository.default()
					.then(response => {
						if(response.data) {
							store.dispatch('location/setAddress', {
								id: response.data.address_id,
								description: response.data.pivot.description,
								country: response.data.country,
								administrative_area_level_1: response.data.administrative_area_level_1,
								administrative_area_level_2: response.data.administrative_area_level_2,
								administrative_area_level_3: response.data.administrative_area_level_3,
								locality: response.data.locality,
								sublocality: response.data.sublocality,
								route: response.data.route,
								street_number: response.data.street_number,
								postal_code: response.data.postal_code,
								division: response.data.division,
								geolocation: {
									lat: response.data.geolocation.coordinates[0],
									lng: response.data.geolocation.coordinates[1]
								}
							}).then(redirectNext).catch(catchError);
						}else{
							redirectNext();
						}
					}).catch(catchError);
			}

			const googleButton = computed(() => {
				if(!isDefined.value) return true;
				else return (microsite.value.logins && microsite.value.logins.indexOf('google') !== -1);
			});

			const facebookButton = computed(() => {
				if(!isDefined.value) return true;
				else return (microsite.value.logins && microsite.value.logins.indexOf('facebook') !== -1);
			});

			return { isDefined, submitting, setUser, googleButton, facebookButton };
		}
	}
</script>